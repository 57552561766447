import { Routes } from '@angular/router';
import { ResourceGuard } from 'app/core/routeGuards/resource.guard';
import { EPermissionOption, EUserType } from 'app/store/auth/model/models';

export const System_Planning_Routes: Routes = [
  {
    path: 'firm-obligation-export',
    loadChildren: () =>
      import('app/modules/system-planning/pages/firm-obligation-export/firm-obligation-export.module').then(
        m => m.FirmObligationExportModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/system-planning/firm-obligation-export',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'system-planning-fuel-rate',
    loadChildren: () =>
      import('app/modules/system-planning/pages/system-planning-fuel-rate/system-planning-fuel-rate.module').then(
        m => m.SystemPlanningFuelRateModule
      ),
     canActivate: [ResourceGuard],
    data: {
      aclResource: '/system-planning/system-planning-fuel-rate',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: 'average-day-generation',
    loadChildren: () =>
      import('app/modules/system-planning/pages/average-day-generation/average-day-generation.module').then(
        m => m.AverageDayGenerationModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/system-planning/average-day-generation',
      aclAccessLevel: EPermissionOption.ReadOnly,
      userType: EUserType.Internal,
    },
  },
  {
    path: 'operational-capacity-reservation',
    loadChildren: () =>
      import('app/modules/system-planning/pages/operational-capacity-reservation/operational-capacity-reservation.module').then(
        m => m.OperationalCapacityReservationModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/system-planning/operational-capacity-reservation',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: 'operational-capacity-reservation/:id',
    loadChildren: () =>
      import('app/modules/system-planning/pages/operational-capacity-reservation/operational-capacity-reservation-edit/operational-capacity-reservation-edit.module').then(
        m => m.OperationalCapacityReservationEditModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/system-planning/operational-capacity-reservation',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: 'meter-capacity-check/:id',
    loadChildren: () =>
      import('app/modules/system-planning/pages/meter-capacity-check/meter-capacity-check.module').then(
        m => m.MeterCapacityCheckModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/system-planning/meter-capacity-check/{id}',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: 'storage-obligations',
    loadChildren: () =>
      import('app/modules/system-planning/pages/storage-obligations/storage-obligations.module').then(
        m => m.StorageObligationsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/system-planning/storage-obligations',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
];
