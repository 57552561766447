import {
  AverageDay,
  AverageMeasurement,
  LineSequenceCollection,
  LookupCollections,
  MeterCapacityCheckModel,
  MeterCapacityCheckModelCollection,
  Obligation,
  ObligationCollection,
  ReservationList,
} from '@gms/systemplanning-api';
import {
  AverageDayExport,
  OverrideFuelRate,
  OverrideFuelRateCollection,
  ReservationEntry,
  StorageObligationList,
} from '@gms/systemplanning-api/model/models';
import { IDataState } from 'app/store/app/app.models';
import { getInitialState } from 'app/store/system-planning/system-planning.utils';

export interface ISystemPlanningState {
  fetchObligations: IDataState<ObligationCollection>;
  exportObligations: IDataState<ObligationCollection>;
  meterCapacityGuid: IDataState<string>;
  meterCapacityCheckCollection: IDataState<MeterCapacityCheckModelCollection>;
  meterCapacityCheck: IDataState<MeterCapacityCheckModel>;
  operationalCapacityReservationCollection: IDataState<ReservationList>;
  lineSequenceCollection: IDataState<LineSequenceCollection>;
  operationalCapacityReservationEntry: IDataState<ReservationEntry>;
  storageObligationCollection: IDataState<StorageObligationList>;
  exportStorageObligationCollection: IDataState<StorageObligationList>;
  exportAverageDay: IDataState<AverageDayExport>;

  averageDay: AverageDay;
  averageDayMeasurements: AverageMeasurement[];
  totalAverageDaysCount: number;
  fetchAverageDayCollectionLoading: boolean;
  fetchAverageDayCollectionError: Error;

  editingAverageDay: AverageDay;
  saveAverageDayLoading: boolean;
  saveAverageDayError: boolean;
  networkResultStatus: string;

  averageDayLookup: LookupCollections;
  averageDayLookupLoading: boolean;
  averageDayLookupError: Error;

  averageDayIdDefault: number;
  averageDayIdDefaultLoading: boolean;
  averageDayIdDefaultError: boolean;
  updateOverrideFuelRates: IDataState<OverrideFuelRate[]>;
  fetchOverrideFuelRateCollections: IDataState<OverrideFuelRateCollection>;
  exportOverrideFuelRate: IDataState<OverrideFuelRateCollection>;
}

export const initialSystemPlanningState: ISystemPlanningState = {
  fetchObligations: getInitialState(),
  exportObligations: getInitialState(),
  meterCapacityGuid: getInitialState(),
  meterCapacityCheckCollection: getInitialState(),
  meterCapacityCheck: getInitialState(),
  operationalCapacityReservationCollection: getInitialState(),
  lineSequenceCollection: getInitialState(),
  operationalCapacityReservationEntry: getInitialState(),
  storageObligationCollection: getInitialState(),
  exportStorageObligationCollection: getInitialState(),
  exportAverageDay: getInitialState(),
  averageDay: null,
  averageDayMeasurements: null,
  totalAverageDaysCount: 0,
  fetchAverageDayCollectionLoading: false,
  fetchAverageDayCollectionError: null,
  editingAverageDay: null,
  saveAverageDayLoading: false,
  saveAverageDayError: null,
  networkResultStatus: '',
  averageDayLookup: null,
  averageDayLookupError: null,
  averageDayLookupLoading: false,
  averageDayIdDefault: null,
  averageDayIdDefaultLoading: false,
  averageDayIdDefaultError: null,
  updateOverrideFuelRates: getInitialState(),
  fetchOverrideFuelRateCollections: getInitialState(),
  exportOverrideFuelRate: getInitialState()
};
